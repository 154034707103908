const images = require.context('../img', true)

import $ from "jquery";
import popper from "popper.js";
import bootstrap from "bootstrap";
import datepicker from "bootstrap-datepicker";
import autocomplete from "devbridge-autocomplete";
import 'lazysizes';

import '../scss/main'

import '../src/index'